import debug from "./debugUtils";

import { getWeblabTreatments, getWeblabTreatmentsAndTrigger, recordWeblabTrigger } from "./ajaxUtils";

const weblabTreatmentCache = new Map<string, string>();
const weblabTriggerCache = new Set<string>;

// Use localStorage to persist weblab states between QV launches so we don't have the UX redraw every launch
// that might occur if the treatment has changed the behavior of items already on the screen.
const getTreatmentsWithTTL = async (weblabIds: string[]) => {
    debug.log(`getTreatmentsWithTTL: ${JSON.stringify(weblabIds)}`);
    const treatments: Record<string, string> = {};
    const expiredWeblabIds: string[] = [];
    weblabIds.forEach(weblabId => {
        const key = `TTL:${weblabId}`;
        const item = localStorage.getItem(key);
        debug.log(`getTreatmentsWithTTL: localStorage: ${key} contains ${item}`);
        const [expirationTTLString, treatment] = item?.split(":") ?? [];
        const expirationTTL = Number(expirationTTLString);
        if (expirationTTL > Date.now() && treatment) {
            treatments[weblabId] = treatment;
        } else {
            expiredWeblabIds.push(weblabId);
        }
    });
    debug.log(`getTreatmentsWithTTL: fetching uncached/expired weblabs ${JSON.stringify(expiredWeblabIds)}`);
    if (expiredWeblabIds.length > 0) {
        const fetchedTreatments = await getWeblabTreatments(expiredWeblabIds);
        expiredWeblabIds.forEach(weblabId => {
            const key = `TTL:${weblabId}`;
            let treatment = fetchedTreatments[weblabId];
            if (!treatment) {
                debug.error(`getTreatmentsWithTTL: returned treatment for ${weblabId} is ${treatment}`);
            }
            treatment = treatment ?? "C";
            const expirationTTL = Date.now() + (60 * 60 * 1000); // 1 hour as milliseconds
            debug.log(`getTreatmentsWithTTL: setting localStorage: ${key} with TTL ${expirationTTL} and treatment ${treatment}`);
            localStorage.setItem(key, `${expirationTTL}:${treatment}`);
            treatments[weblabId] = treatment;
        });
    }
    return treatments;
};

const getTreatment = async (weblabId: string) => {
    debug.log(`getTreatment: ${weblabId}`);
    const cachedTreatment = weblabTreatmentCache.get(weblabId);
    if (cachedTreatment) {
        debug.log(`getTreatment: ${weblabId} - cache hit: ${cachedTreatment}`);
        return cachedTreatment;
    }
    return getWeblabTreatments([weblabId])
        .then(treatments => {
            const treatment = treatments[weblabId];
            weblabTreatmentCache.set(weblabId, treatment);
            debug.log(`getTreatment: ${weblabId} - caching treatment: ${treatment}`);
            return treatment
        });
};

const getTreatmentAndTrigger = async (weblabId: string) => {
    debug.log(`getTreatmentAndTrigger: ${weblabId}`);
    const cachedTreatment = weblabTreatmentCache.get(weblabId);
    if (cachedTreatment) {
        debug.log(`getTreatmentAndTrigger: ${weblabId} - cache hit: ${cachedTreatment}`);
        if (!weblabTriggerCache.has(weblabId)) { triggerWeblab(weblabId); }
        return cachedTreatment;
    }
    return getWeblabTreatmentsAndTrigger([weblabId])
        .then(treatments => {
            const treatment = treatments[weblabId];
            weblabTreatmentCache.set(weblabId, treatment);
            weblabTriggerCache.add(weblabId);
            debug.log(`getTreatmentAndTrigger: ${weblabId} - caching treatment and trigger: ${treatment}`);
            return treatment
        });
};

const triggerWeblab = async (weblabId: string) => {
    if (weblabTriggerCache.has(weblabId)) {
        debug.log(`triggerWeblab: ${weblabId} - cache hit`);
        return;
    }
    return recordWeblabTrigger([weblabId]).then(() => {
        weblabTriggerCache.add(weblabId);
        debug.log(`triggerWeblab: ${weblabId} - caching trigger`);
    });
};

const isT1 = async (weblabId: string) => (await getTreatment(weblabId)) === "T1";
const isT1AndTrigger = async (weblabId: string) => (await getTreatmentAndTrigger(weblabId)) === "T1";

const weblabUtils = {
    isT1,
    isT1AndTrigger,
    triggerWeblab,
    getTreatmentsWithTTL,
};

export default weblabUtils;
