import React, { memo } from "react";
import { newMetricsWithContext } from "src/utils/metricsUtils";
import { ExpandableRichContent, ScrollOnCollapse } from "../ExpandableContent/ExpandableContent";

interface Props {
    asin: string;
    description?: RichContent;
    truncatedHeight?: string;
    getScrollData: () => { scrollAreaScrollTop: number; miniHeaderOffset: number; } | undefined;
}

const BookDescription: React.FC<Props> = ({ asin, description, truncatedHeight, getScrollData }) => {
    const metrics = newMetricsWithContext("BookDescription", asin);

    return (
        <ExpandableRichContent
            richContent={description || {}}
            truncatedMinHeight={"25vh"}
            truncatedHeight={truncatedHeight}
            scrollOnCollapse={ScrollOnCollapse.SELF}
            getScrollData={getScrollData}
            metrics={metrics}
        />
    );
};

export default memo(BookDescription);
