import React, { forwardRef, memo, useContext, useRef } from "react";
import BookDescription from "src/components/BookDescription/BookDescription";
import BookSeries from "../BookSeries/BookSeries";
import MoreDetails from "src/components/MoreDetails/MoreDetails";
import CustomerReviews from "src/components/CustomerReviews/CustomerReviews";
import styles from "./QuickViewSlideContent.module.scss";
import Spinner from "../Spinner/Spinner";
import debug from "src/utils/debugUtils";
import TranslationsContext from "src/contexts/TranslationsContext";
import MoreLikeThisMES from "../MoreLikeThis/MoreLikeThisMES";
import SeeAllDetailsFooter from "../SeeAllDetailsFooter/SeeAllDetailsFooter";
import DeviceContext from "src/contexts/DeviceContext";

type PropTypes = {
    mesKey: string;
    metadata: QuickViewAsinMetadata;
    isReady: boolean;
    onClickCustomerReviews: VoidFunction;
    getScrollData: () => { scrollAreaScrollTop: number; miniHeaderOffset: number; } | undefined;
    disableScrolling?: boolean;
    enableMes?: boolean;
};

const QuickViewSlideContent = forwardRef<HTMLDivElement, PropTypes>(function QuickViewSlideContent(props, ref) {
    const context = useContext(DeviceContext);
    const translations = useContext(TranslationsContext);
    const waitStartedRef = useRef(Date.now());

    const isLongWait = () => {
        const elapsed = Date.now() - waitStartedRef.current;
        return (elapsed > 2500);
    };

    const getErrorMessage = () => {
        if (!(props.metadata.loadFailed)) {
            return;
        }
        if (props.metadata.unrecoverableError) {
            return (
                <div className={styles.loadFailure}>
                    <p>
                        <i>&quot;Failure is the condiment that gives success its flavor.&quot;</i>
                        <br/>
                        <b>— Truman Capote</b>
                    </p>
                    <br/>
                    {translations.getText("unrecoverable-failure-message")}
                </div>
            );
        }
        if (navigator.onLine === false) {
            return (
                <div className={styles.loadFailure}>
                    {translations.getText("no-internet-connection-title")}
                    <br/>
                    <br/>
                    {translations.getText("no-internet-connection-message")}
                </div>
            );
        }
        if (isLongWait()) {
            return (
                <div className={styles.loadFailure}>
                    {translations.getText("long-request-message")}
                </div>
            );
        }
    };

    if (!props.isReady) {
        return (
            <div style={{ paddingTop: "20px" }}>
                {props.metadata.unrecoverableError !== true && (<Spinner />)}
                { getErrorMessage() }
                {(props.metadata.loadFailed) && debug.get('enableLoadFailureMessages') &&
                    <div className={styles.loadFailure}>
                        { props.metadata.error }
                    </div>
                }
            </div>
        );
    }

    return (
        <>
        <div className={styles.content}>
            {props.metadata.series && <BookSeries asin={props.metadata.asin} seriesMetadata={props.metadata.series} />}
            <BookDescription asin={props.metadata.asin} description={props.metadata.description} truncatedHeight="25vh" getScrollData={props.getScrollData}/>
            <div className={styles.innerFooter}>
                {props.metadata.overview && props.metadata.isEbook && (<>
                    <hr className={styles.contentDivider} aria-hidden />
                    <MoreDetails asin={props.metadata.asin} overview={props.metadata.overview} disableScrolling={props.disableScrolling} getScrollData={props.getScrollData} />
                </>)}

                {props.enableMes && props.metadata.mes && props.metadata.mes.length > 0 && (<>
                    <hr className={styles.contentDivider} aria-hidden />
                    <MoreLikeThisMES mesKey={props.mesKey} items={props.metadata.mes} disableScrolling={props.disableScrolling} />
                </>)}

                {props.metadata.reviewsSummary && <>
                    <hr className={styles.contentDivider} aria-hidden />
                    <section tabIndex={-1}>
                        <CustomerReviews asin={props.metadata.asin} title={props.metadata.title} numberOfReviews={props.metadata.reviewsSummary?.numberOfReviews} ref={ref} getScrollData={props.getScrollData}/>
                    </section>
                </>}
            </div>

        </div>
        <div className={styles.spacer}></div>
        {context.enableSeeAllDetailsUnpinning && (<SeeAllDetailsFooter metadata={props.metadata} />)}
        </>
    );
});

export default memo(QuickViewSlideContent);
