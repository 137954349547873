import React, { useContext, useRef } from "react";
import styles from "./MoreDetails.module.scss";
import TypeSettingLight from "src/images/overview/light/book_details-advanced_type_setting.png";
import DimensionsLight from "src/images/overview/light/book_details-dimensions.png";
import EbookPagesLight from "src/images/overview/light/book_details-ebook_pages.png";
import EditionLight from "src/images/overview/light/book_details-edition.png";
import FileSizeLight from "src/images/overview/light/book_details-file_size.png";
import FionaPagesLight from "src/images/overview/light/book_details-fiona_pages.png";
import GradeLevelLight from "src/images/overview/light/book_details-grade_level.png";
import Isbn10Light from "src/images/overview/light/book_details-isbn10.png";
import Isbn13Light from "src/images/overview/light/book_details-isbn13.png";
import LexileLight from "src/images/overview/light/book_details-lexile.png";
import PageFlipLight from "src/images/overview/light/book_details-page_flip.png";
import PublicationDateLight from "src/images/overview/light/book_details-publication_date.png";
import ReadingAgeLight from "src/images/overview/light/book_details-reading_age.png";
import WordWiseLight from "src/images/overview/light/book_details-word_wise.png";
import LanguageLight from "src/images/overview/light/book_details-language.png";
import PublisherLight from "src/images/overview/light/book_details-publisher.png";
import TypeSettingDark from "src/images/overview/dark/book_details-advanced_type_setting.png";
import DimensionsDark from "src/images/overview/dark/book_details-dimensions.png";
import EbookPagesDark from "src/images/overview/dark/book_details-ebook_pages.png";
import EditionDark from "src/images/overview/dark/book_details-edition.png";
import FileSizeDark from "src/images/overview/dark/book_details-file_size.png";
import FionaPagesDark from "src/images/overview/dark/book_details-fiona_pages.png";
import GradeLevelDark from "src/images/overview/dark/book_details-grade_level.png";
import Isbn10Dark from "src/images/overview/dark/book_details-isbn10.png";
import Isbn13Dark from "src/images/overview/dark/book_details-isbn13.png";
import LexileDark from "src/images/overview/dark/book_details-lexile.png";
import PageFlipDark from "src/images/overview/dark/book_details-page_flip.png";
import PublicationDateDark from "src/images/overview/dark/book_details-publication_date.png";
import ReadingAgeDark from "src/images/overview/dark/book_details-reading_age.png";
import WordWiseDark from "src/images/overview/dark/book_details-word_wise.png";
import LanguageDark from "src/images/overview/dark/book_details-language.png";
import PublisherDark from "src/images/overview/dark/book_details-publisher.png";
import DeviceContext from "src/contexts/DeviceContext";
import { Theme } from "src/utils/themeUtils";
import { newMetricsWithContext, recordBehavioralMetric } from "src/utils/metricsUtils";
import debug from "src/utils/debugUtils";
import TranslationsContext from "src/contexts/TranslationsContext";
import ExpandableContent, { ScrollOnCollapse } from "../ExpandableContent/ExpandableContent";

interface PropTypes {
    asin: string;
    overview?: OverviewMetadata[];
    disableScrolling?: boolean;
    getScrollData: () => { scrollAreaScrollTop: number; miniHeaderOffset: number; } | undefined;
}

// TODO: Update these to work with Audible asins as well, which tend to follow as:
// audiobook_details-author, audiobook_details-listening_length, etc.

const icons: { [keys: string]: string[] } = {
    "book_details-advanced_type_setting": [ TypeSettingLight, TypeSettingDark],
    "book_details-dimensions": [ DimensionsLight, DimensionsDark],
    "book_details-ebook_pages": [ EbookPagesLight, EbookPagesDark],
    "book_details-edition": [ EditionLight, EditionDark],
    "book_details-file_size": [ FileSizeLight, FileSizeDark],
    "book_details-fiona_pages": [ FionaPagesLight, FionaPagesDark],
    "book_details-grade_level": [ GradeLevelLight, GradeLevelDark],
    "book_details-isbn10": [ Isbn10Light, Isbn10Dark],
    "book_details-isbn13": [ Isbn13Light, Isbn13Dark],
    "book_details-lexile": [ LexileLight, LexileDark],
    "book_details-page_flip": [ PageFlipLight, PageFlipDark],
    "book_details-publication_date": [ PublicationDateLight, PublicationDateDark],
    "book_details-publisher": [ PublisherLight, PublisherDark],
    "book_details-reading_age": [ ReadingAgeLight, ReadingAgeDark],
    "book_details-word_wise": [ WordWiseLight, WordWiseDark],
    "language": [ LanguageLight, LanguageDark]
};

const MoreDetails: React.FC<PropTypes> = ({ asin, overview, disableScrolling, getScrollData }: PropTypes) => {
    const context = useContext(DeviceContext);
    const translations = useContext(TranslationsContext);
    const scrollMetricRecordedRef = useRef(false);

    if (!overview) return null;

    const metrics = newMetricsWithContext("BookDetails", asin);
    const enableBookDetailsTable = debug.get("enableBookDetailsTable");
    const allowMissingIcons = debug.get("enableBookDetailsWithMissingIcons");
    const disableBookDetailsIcons = debug.get("disableBookDetailsIcons");

    const handleScroll = () => {
        if (!scrollMetricRecordedRef.current) {
            scrollMetricRecordedRef.current = true;
            recordBehavioralMetric({namespace: "MoreDetails", qv_asin: asin }, "Scrolled", 1);
        }
    }

    const getOverviewSections = () => {
        return overview.map((metadata, index) => {
            const label = metadata.labelContent?.fragments?.[0]?.text;

            const labelId = metadata.labelId || "";
            const icon = context.theme === Theme.DARK 
                ? icons[labelId]?.[1]
                : icons[labelId]?.[0];

            const fragment = metadata.value?.fragments?.[0];
            const value = fragment?.text ?? fragment?.inlinePopover?.triggeringContent?.text;

            if (!(label && value && (icon || allowMissingIcons || disableBookDetailsIcons))) { return null; }

            if (enableBookDetailsTable) {
                return (
                    <tr className={styles.itemRow} key={`${labelId}_${index}`}>
                        {!disableBookDetailsIcons && (
                            <td className={styles.itemIcon}>{icon && <img className={styles.itemIcon} src={icon} alt="" />}</td>
                        )}
                        <td className={styles.itemLabel}><div className={styles.itemLabel}>{label}</div></td>
                        <td className={styles.itemValue}><div className={styles.itemValue}>{value}</div></td>
                    </tr>
                );
            }

            return (
                <div className={styles.overviewSection} key={index}>
                    <div className={styles.label}>{label}</div>
                    <div>
                        <img src={icon} alt="" />
                    </div>
                    <div className={styles.value}>{value}</div>
                </div>
            );
        });
    };

    // TODO: Do we need to check if we should hide the section?
    if (enableBookDetailsTable) {
        const contents = getOverviewSections();
        if (contents == null) {
            return null;
        }
        return (<section className={`${styles.bookDetails} ${styles[context.theme]}}`}>
            <div className={styles.sectionLabel}>
                {translations.getText("book-details")}
            </div>

            <ExpandableContent
                content={<table>{contents}</table>}
                truncatedHeight={"min(100px, 15vh)"}
                scrollOnCollapse={ScrollOnCollapse.PARENT}
                getScrollData={getScrollData}
                metrics={metrics}
            />
        </section>);
    }

    return <section className={`${styles.horizontalScroller} ${styles[context.theme]} ${disableScrolling ? styles.disableScrolling : ""}`} onScroll={handleScroll}>
            {getOverviewSections()}
        </section>;
};

export default MoreDetails;
