import React, { forwardRef, memo, useContext } from "react";
import { FailableResult } from "src/contexts/AcquisitionManagerContext";
import DeviceContext from "src/contexts/DeviceContext";
import { CONTENT_TYPE } from "src/utils/asinUtils";
import AddToListButton from "../AddToListButton/AddToListButton";
import BookBuyButton from "../BookBuyButton/BookBuyButton";
import styles from "./QuickViewSlideMiniHeader.module.scss";
import ReKindleButton from "../ReKindleButton/ReKindleButton";

type PropTypes = {
    metadata: QuickViewAsinMetadata;
    backgroundColor: string;
    isVisible: boolean;
    updateDownloadState: (contentType: CONTENT_TYPE) => void;
    redeemOfferByActionType: (actionType: string, callback: VoidFunction) => Promise<FailableResult>;
    hasFullBook: boolean;
    showReKindleCX: boolean;
    canAddToLibraryForFree?: boolean;
    bookStatus: BookStatus;
    sampleOwned: boolean;
    bookActionInProgress: boolean;
    handleHeaderTap?: () => void;
};

const QuickViewSlideMiniHeader = forwardRef<HTMLDivElement, PropTypes>(function QuickViewSlideMiniHeader(props, ref) {
    const context = useContext(DeviceContext);

    const headerTapped = () => props.handleHeaderTap?.();

    const getMiniHeaderButton = () => {
        if (props.showReKindleCX) {
            return <ReKindleButton metadata={props.metadata} />;
        }

        if (props.hasFullBook || props.sampleOwned || props.metadata.canBorrow || props.metadata.hasSample) {
            return <BookBuyButton 
                item={props.metadata}
                isMiniHeader={true}
                bookStatus={props.bookStatus}
                sampleOwned={props.sampleOwned}
                bookActionInProgress={props.bookActionInProgress}
                hasFullBook={props.hasFullBook}
                primaryCtaAlreadyShown={props.showReKindleCX}
                canAddToLibraryForFree={props.canAddToLibraryForFree === true}
                updateDownloadState={props.updateDownloadState}
                redeemOfferByActionType={props.redeemOfferByActionType}
            />;
        }
        return <AddToListButton asin={props.metadata.asin} />;
    }

    return (
        <div
            className={`${styles.miniHeader} ${styles[context.theme]} ${props.isVisible ? styles.show : ""}`}
            ref={ref}
            style={{
                backgroundColor: props.backgroundColor,
            }}
            aria-hidden={!props.isVisible}
        >
            <div className={styles.content}>
                <div className={styles.metadataContainer} onClick={headerTapped}>
                    <div className={styles.title}>{props.metadata.title}</div>
                    <div className={styles.authors}>{props.metadata.authors}</div>
                </div>

                <div className={styles.buttonContainer}>
                    { getMiniHeaderButton() }
                </div>
            </div>
        </div>
    );
});

export default memo(QuickViewSlideMiniHeader);
