import React, { useCallback, useContext } from "react";
import styles from "./SeeAllDetailsFooter.module.scss";
import DeviceContext from "src/contexts/DeviceContext";
import { getCurrentBaseUrl } from "src/utils/urlUtils";
import { getDetailsUrl } from "src/utils/asinUtils";
import TranslationsContext from "src/contexts/TranslationsContext";
import { NativeAPI } from "src/utils/deviceUtils";
import { newMetricsWithContext } from "src/utils/metricsUtils";

type PropTypes = {
    metadata: QuickViewAsinMetadata;
};

const SeeAllDetailsFooter: React.FC<PropTypes> = ({ metadata }: PropTypes) => {
    const context = useContext(DeviceContext);
    const translations = useContext(TranslationsContext);
    const asin = metadata.asin;

    const seeAllDetailsClick = useCallback(() => {
        // Keeping old metrics context since that's used by the QuickSight dashboard
        newMetricsWithContext("QuickViewSlide", asin).recordBehavioralMetric("SeeAllDetails.click", 1);
        const detailLink = metadata.additionalData?.detailLink;
        if (detailLink) {
            NativeAPI.openWebPage(detailLink, undefined, metadata.additionalData?.reftag);
        } else {
            const detailPageUrl = getDetailsUrl(
                getCurrentBaseUrl(),
                asin,
                metadata.additionalData?.reftag || "quick_view_ref_tag", // TODO add correct reftag?
                context.theme
            );
            NativeAPI.openWebPage(detailPageUrl);
        }
    }, [asin, context.theme, metadata.additionalData?.detailLink, metadata.additionalData?.reftag]);

    return (
        <div className={[styles.detailsLinkContainer, styles[context.theme]].join(" ")}>
            <button className={styles.detailsLinkButton} type="button" onClick={seeAllDetailsClick}>
                {translations.getText("see-all-details")}
            </button>
        </div>
    );
};

export default SeeAllDetailsFooter;
