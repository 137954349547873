import React, { useContext } from "react";
import { StarRatingBar } from "../StarRatingBar/StarRatingBar";
import styles from "./CustomerReview.module.scss";
import reviewProfileEnthusiastBadge from "src/images/review_profile_enthusiast_badge.svg";
import DeviceContext from "src/contexts/DeviceContext";
import TranslationsContext from "src/contexts/TranslationsContext";
import { newMetricsWithContext } from "src/utils/metricsUtils";
import { ExpandableRichContent, ScrollOnCollapse } from "../ExpandableContent/ExpandableContent";

interface CustomerReviewProps {
    asin: string;
    review: CustomerReview;
    getScrollData: () => { scrollAreaScrollTop: number; miniHeaderOffset: number; } | undefined;
}

const createReviewsSummary = (stars: number): ReviewsSummary => {
    return {
        numberOfStars: Math.floor(stars),
        hasHalfStar: stars % 1 > 0, // Reviews seem to only ever be full star values but just in case
    };
};

const CustomerReview: React.FC<CustomerReviewProps> = ({ asin, review, getScrollData }) => {
    const context = useContext(DeviceContext);
    const translations = useContext(TranslationsContext);
    const metrics = newMetricsWithContext("CustomerReview", asin);

    if (!review) {
        metrics.recordBehavioralMetric("InvalidReview", 1);
        return null;
    }

    if (!review.contentContributor) {
        metrics.recordBehavioralMetric("InvalidReviewContentContributor", 1);
        return null;
    }

    return (
        <div className={`${styles.customerReview} ${styles[context.theme]}`}>
            <div className={styles.contributor}>
                <div className={styles.profileImageContainer}>
                    <img
                        src={review.contentContributor.customerProfile.image.url}
                        alt=""
                        className={styles.profileImage}
                        height="37px"
                        width="37px"
                        loading="lazy"
                        onError={(e) => { (e.target as HTMLImageElement).style.display = 'none' }}
                    />
                    {review.contentContributor.customerProfile.badges?.length > 0 && (
                        <img src={reviewProfileEnthusiastBadge} alt="" className={styles.profileImageBadgeOverlay} />
                    )}
                </div>
                <span>{review.contentContributor.customerProfile.publicName}</span>
                {review.contentContributor.customerProfile.badges?.length > 0 && (
                    <span className={styles.profileBadge}>
                        {translations.getText("top-contributor")}
                        {review.contentContributor.customerProfile.badges.join(", ")}
                    </span>
                )}
            </div>
            <div className={styles.rating}>
                <StarRatingBar reviewsSummary={createReviewsSummary(review.stars)} />
                {review.contentContributor.contributorRelationshipProgramList.map((program, index) => (
                    <span key={index} className={styles.programName}>
                        {program.contributorRelationshipProgramName}
                    </span>
                ))}
            </div>
            <div className={styles.title}>{review.title}</div>
            <div className={styles.origin}>{review.originDescription}</div>
            <ExpandableRichContent
                richContent={review.contentAbstract.richAbstract}
                truncatedHeight="25vh"
                scrollOnCollapse={ScrollOnCollapse.PARENT}
                getScrollData={getScrollData}
                metrics={metrics}
            />
        </div>
    );
};

export default CustomerReview;
