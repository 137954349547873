import React, { useCallback, useContext, useRef } from "react";
import styles from "./ReKindleButton.module.scss";
import TranslationsContext from "src/contexts/TranslationsContext";
import { emitClickstream, recordBehavioralMetric } from "src/utils/metricsUtils";
import { isReKindleSupported, NativeAPI } from "src/utils/deviceUtils";
import WorkflowContext from "src/contexts/WorkflowContext";
import focusHelper from "src/utils/focusHelper";

type PropTypes = {
    metadata: QuickViewAsinMetadata;
};

const ReKindleButton: React.FC<PropTypes> = ({ metadata }: PropTypes) => {
    const translations = useContext(TranslationsContext);
    const workflowContext = useContext(WorkflowContext);
    const a11yTarget = useRef<HTMLButtonElement>(null);

    const requestA11yFocus = useCallback(() => {
        focusHelper.requestFocus(a11yTarget.current);
    }, [a11yTarget]);

    const reKindleFlow = useCallback(async () => {
        recordBehavioralMetric({namespace: "ReKindleButton", qv_asin: metadata.asin}, "ReKindleButton.click", 1);
        emitClickstream(metadata);
        if (!isReKindleSupported()) {
            recordBehavioralMetric({namespace: "ReKindleButton", qv_asin: metadata.asin}, "ReKindleButton.ErrorCX", 1);
            workflowContext.showReKindleError(requestA11yFocus);
            return;
        }
        NativeAPI.openReKindleInterstitial(metadata);
    }, [metadata, requestA11yFocus, workflowContext]);

    return (
        <button type="button" className={styles.reKindleButton} onClick={reKindleFlow} ref={a11yTarget}>
            <div className={styles.label}>
                {translations.getText("rekindle-cta")}
            </div>
        </button>
    );
};

export default ReKindleButton;
