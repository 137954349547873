import React from "react";

export enum QuickViewIndexUpdateState {
    UPDATE,
    CLOSED,
}

export type QuickViewIndexUpdateCallback = (index: number, state: QuickViewIndexUpdateState) => void;
export interface WorkflowContextI {
    addToList: (asin: string, callback?: VoidFunction) => void;
    borrowLimit: (data: BorrowLimitData, callback?: VoidFunction) => void;
    infoMessage: (message: string) => void;
    showDebugGooey: (mode?: string) => void;
    showReKindleError: (callback?: VoidFunction) => void;
    openQv: (asins: QuickViewAsinMetadata[], initialIndex: number, callback: QuickViewIndexUpdateCallback) => void;
}

const noOp = () => { /* NoOp */ };
const WorkflowContext = React.createContext<WorkflowContextI>({
    addToList: noOp,
    borrowLimit: noOp,
    infoMessage: noOp,
    showDebugGooey: noOp,
    showReKindleError: noOp,
    openQv: noOp,
});

export default WorkflowContext;
