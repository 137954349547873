import React, { useContext, useState, useRef, memo, useEffect } from "react";
import styles from "./MoreLikeThis.module.scss";
import DeviceContext from "src/contexts/DeviceContext";
import WorkflowContext from "src/contexts/WorkflowContext";
import TranslationsContext from "src/contexts/TranslationsContext";
import { getMesMetadata } from "src/utils/asinMetadataUtils";
import BookCoverWithPlaceholder from "../BookCoverWithPlaceholder/BookCoverWithPlaceholder";
import { getProgramSticker } from "src/utils/programLogoUtils";
import MesContext from "src/contexts/MesContext";

type BookImagePropTypes = {
    asin: string;
    tag: string;
    index: number;
    count: number;
    shouldScrollIntoView?: boolean;
    onTapCallback: (index: number) => void;
}

const BookImage: React.FC<BookImagePropTypes> = ({ asin, tag, index, count, shouldScrollIntoView, onTapCallback }: BookImagePropTypes) => {
    const context = useContext(DeviceContext);
    const translations = useContext(TranslationsContext);
    const itemRef = useRef<HTMLLIElement>(null);
    const [metadata, setMetadata] = useState<QuickViewAsinMetadata>({ asin });
    const [hasFetchedMetadata, setHasFetchedMetadata] = useState(false);

    useEffect(() => {
        if (hasFetchedMetadata) { return; }
        setHasFetchedMetadata(true);
        getMesMetadata(asin)
            .then(data => { setMetadata(data); })
            .finally(() => setHasFetchedMetadata(metadata?.loaded === true));
    }, [asin, hasFetchedMetadata, index, metadata?.loaded]);

    useEffect(() => {
        if (shouldScrollIntoView) {
            itemRef.current?.scrollIntoView({ block: "nearest", inline: "center", behavior: "smooth" });
        }
    }, [shouldScrollIntoView]);

    // TODO: Consider removing this after Yihan's backend filtering proves successful
    if (metadata.loaded === true && !metadata.isEbook) {
        return null;
    }

    const stickerCode = getProgramSticker(context.theme, context.domain, metadata.kindleProgram, metadata.isAFR);

    return (
        <li ref={itemRef}
            className={[stickerCode ? styles.bookImageWithSticker : "", styles.bookImage].join(" ")}
        >
            <button
                className={styles.coverButton}
                onClick={() => onTapCallback(index)}
                aria-posinset={index}
                aria-setsize={count}
            >
                <div className={styles.coverWithBadgeWrapper}>
                    {stickerCode && (<div className={metadata.isRPL ? styles.programBadgeRpl : styles.programBadge}>
                    <img src={stickerCode.src} alt={translations.getText(stickerCode.altKey)} />
                </div>)}
                    <BookCoverWithPlaceholder
                        asin={asin}
                        authors={metadata.authors}
                        title={metadata.title ?? ""}
                        physicalId={metadata.physicalId}
                        imagePxSize={200}
                        imagePxType="height"
                        lazy={index > 3}
                    />
                </div>
            </button>
        </li>
    );
};

type PropTypes = {
    mesKey: string;
    disableScrolling?: boolean;
    items: MESRecItem[];
};

const MoreLikeThisMES: React.FC<PropTypes> = ({ mesKey, disableScrolling, items }: PropTypes) => {
    const deviceContext = useContext(DeviceContext);
    const workflowContext = useContext(WorkflowContext);
    const translations = useContext(TranslationsContext);
    const mesContext = useContext(MesContext);

    const scrollToItemIndex = mesContext.getScrollToItemIndex(mesKey);
    const selectedTagIndex = mesContext.getSelectedTagIndex(mesKey);

    const openQvAtIndex = (index: number) => {
        workflowContext.openQv(items[selectedTagIndex].asins?.map(asin => { return { asin: asin }; }) || [], index, mesContext.getQvIndexUpdateCallback(mesKey));
    };

    const getBookCoversComponent = () => {
        const activeGroup = items?.[selectedTagIndex];
        const count = activeGroup.asins?.length ?? 0;
        const tag = activeGroup.tag ?? "";
        return (
            <ul className={`${styles.horizontalScroller} ${styles[deviceContext.theme]} ${disableScrolling ? styles.disableScrolling : ""} ${styles.fixedHeight}`}
                role="listbox"
                aria-label={tag}
            >
                {activeGroup?.asins?.map((asin, index) =>
                    <BookImage
                        key={asin + index + activeGroup?.tag} asin={asin} tag={tag} index={index} count={count} onTapCallback={openQvAtIndex} shouldScrollIntoView={scrollToItemIndex === index}
                    />
                )}
            </ul>
        );
    };

    const getTagsComponent = () => {
        return (
            <ul
                className={`${styles.horizontalScroller} ${styles.tagsCarousel} ${disableScrolling ? styles.disableScrolling : ""}`}
                role="listbox"
                aria-label={translations.getText("more-like-this")}
            >
                {items.map((item, index) =>
                    <li key={mesKey + (item.tag ?? "") + index}>
                        <button
                            className={[styles.tagsPill, index === selectedTagIndex ? styles.selected : ""].join(" ")}
                            onClick={() => {
                                mesContext.setSelectedTagIndex(mesKey, index);
                                mesContext.setScrollToItemIndex(mesKey, -1);
                            }}
                            aria-selected={index === selectedTagIndex}
                            aria-setsize={items.length}
                            aria-posinset={index}
                        >
                            {item.tag}
                        </button>
                    </li>
                )}
                {/* Add an end spacer so the last tag pill can scroll onto screen without the gradient covering it */}
                <li className={styles.tagsEndSpacer} aria-hidden>&nbsp;</li>
            </ul>
        );
    };

    return (
        <section className={[styles[deviceContext.theme]].join(" ")}>
            <div className={styles.label}>
                {translations.getText("more-like-this")}
            </div>
            {getTagsComponent()}
            {getBookCoversComponent()}
        </section>
    );
};

export default memo(MoreLikeThisMES);
