import React, { memo, useContext, useState } from "react";
import BookCoverWithPlaceholder from "../BookCoverWithPlaceholder/BookCoverWithPlaceholder";
import { getProgramSticker } from "src/utils/programLogoUtils";
import styles from "./QuickViewSlideHeader.module.scss";
import WorkflowContext from "src/contexts/WorkflowContext";
import debug from "src/utils/debugUtils";
import DeviceContext from "src/contexts/DeviceContext";
import TranslationsContext from "src/contexts/TranslationsContext";
import { StarRatingBar } from "../StarRatingBar/StarRatingBar";
import AddToListButton from "../AddToListButton/AddToListButton";
import ShareButton from "../ShareButton/ShareButton";
import BookBuyButton from "../BookBuyButton/BookBuyButton";
import AudioSampleButton from "../AudioSampleButton/AudioSampleButton";
import { CONTENT_TYPE } from "src/utils/asinUtils";
import { FailableResult } from "src/contexts/AcquisitionManagerContext";
import SponsoredLabel from "src/components/SponsoredLabel/SponsoredLabel";
import { NativeAPI } from "src/utils/deviceUtils";
import { interpolateTranslation, releaseDateString } from "../Translations/Translations";
import weblabUtils from "src/utils/weblabUtils";
import RplUpsell from "../RplUpsell/RplUpsell";
import ReKindleButton from "../ReKindleButton/ReKindleButton";

type PropTypes = {
    metadata: QuickViewAsinMetadata;
    isReady: boolean;
    isSponsored: boolean;
    onClickCustomerReviews: VoidFunction;
    updateDownloadState: (contentType: CONTENT_TYPE) => void;
    redeemOfferByActionType: (actionType: string, callback: VoidFunction) => Promise<FailableResult>;
    showReKindleCX: boolean;
    hasFullBook: boolean;
    bookStatus: BookStatus;
    sampleOwned: boolean;
    bookActionInProgress: boolean;
};

const addToLibraryFreeSupportedAmazonUrls = [
    "Amazon.co.jp",
];

const openKindleStoreTermsOfUse = () => NativeAPI.openWebPage('/gp/help/customer/display.html?nodeId=201014950');

const openSellerOfRecordLegalText = () => NativeAPI.openWebPage('/gp/help/customer/display.html?ie=UTF8&nodeId=202008070');

type PostPurchaseMessageProps = {
    metadata: QuickViewAsinMetadata
};
const PostPurchaseMessage: React.FC<PostPurchaseMessageProps> = ({ metadata }) => {
    if (metadata.postPurchaseMessageShort || debug.get("forcePostPurchaseMessage")) {
        return (<div className={styles.postPurchaseMessage}>
            {metadata.postPurchaseMessageShort
                ? metadata.postPurchaseMessageShort
                : "This is a post purchase message"
            }
        </div>);
    }
    return null;
};

const QuickViewSlideHeader: React.FC<PropTypes> = ({ 
    metadata,
    isReady,
    isSponsored,
    onClickCustomerReviews,
    updateDownloadState,
    redeemOfferByActionType,
    showReKindleCX,
    hasFullBook,
    bookStatus,
    sampleOwned,
    bookActionInProgress
}: PropTypes) => {
    const context = useContext(DeviceContext);
    const workflowContext = useContext(WorkflowContext);
    const translations = useContext(TranslationsContext);
    const amazonUrl = `A${context.domain.slice(1)}`;
    const [truncateTitle, setTruncateTitle] = useState(true);
    const toggleTruncateTitle = () => setTruncateTitle(!truncateTitle);
    const [truncateAuthors, setTruncateAuthors] = useState(true);
    const toggleTruncateAuthors = () => setTruncateAuthors(!truncateAuthors);
    const canAddToLibraryForFree = !!(metadata.canBuyForFree && (addToLibraryFreeSupportedAmazonUrls.includes(amazonUrl) || debug.get("forceAddToYourLibraryForFree")));

    const tapsForDebug = 5;
    let taps = 0;
    let timeout: NodeJS.Timeout | undefined = undefined;

    const handleCoverTap = () => {
        clearTimeout(timeout);
        taps += 1;
        if (taps >= tapsForDebug) {
            taps = 0;
            if (debug.get("enableDebugGooey")) {
                workflowContext.showDebugGooey();
            } else {
                weblabUtils.isT1AndTrigger("KINDLE_QV_DEBUG_GOOEY_ACCESS_913834").then(isT1 => {
                    if (isT1) {
                        debug.set("enableDebugGooey", true);
                        workflowContext.showDebugGooey();
                    }
                });
            }
        } else {
            debug.log(`You are ${tapsForDebug - taps} taps away from the debug GUI`);
            timeout = setTimeout(() => { taps = 0; }, 2000);
        }
    };

    const stickerCode = getProgramSticker(context.theme, context.domain, metadata.kindleProgram, metadata.isAFR);

    const getReleaseDateSection = (): JSX.Element | undefined => {
        if (!hasFullBook && metadata.releaseDate && metadata.releaseDate.isPrerelease && (metadata.canPreOrder || !metadata.canBuy)) {
            return (
                <div className={styles.releaseDateHolder}>
                    {interpolateTranslation(
                        translations.getText("not-yet-released-notice"),
                        "releaseDate",
                        (<span className={styles.releaseDate}>{releaseDateString(context.locale, metadata.releaseDate)}</span>)
                    )}
                </div>
            );
        }
    };

    const getStickerSection = (): JSX.Element | undefined => {
        if (stickerCode) {
            return (<div className={metadata.isRPL ? styles.programBadgeRpl : styles.programBadge}>
                <img src={stickerCode.src} alt={translations.getText(stickerCode.altKey)} />
            </div>);
        }
    };

    const getHeaderBottomSection = (): JSX.Element => {
        const isJPAmazon = context.domain === "amazon.co.jp";
        const probablyPreordered = !!(metadata.postPurchaseMessageShort && metadata.releaseDate?.isPrerelease)
        const showReKindle = showReKindleCX && !canAddToLibraryForFree && !probablyPreordered;

        return (<>
            {metadata.isRPL && <RplUpsell item={metadata}/>}

            {showReKindle && <div className={styles.reKindleButtonSection}><ReKindleButton metadata={metadata} /></div> }

            {(!metadata.canPreOrder || hasFullBook) && (<div className={styles.bottomSection}>
                <BookBuyButton 
                    item={metadata}
                    bookStatus={bookStatus} 
                    sampleOwned={sampleOwned} 
                    bookActionInProgress={bookActionInProgress} 
                    hasFullBook={hasFullBook} 
                    canAddToLibraryForFree={canAddToLibraryForFree}
                    primaryCtaAlreadyShown={showReKindle}
                    updateDownloadState={updateDownloadState} 
                    redeemOfferByActionType={redeemOfferByActionType}
                />
                {metadata.audibleUrl && !metadata.canPreOrder && <AudioSampleButton item={metadata} />}
            </div>)}

            {!hasFullBook && !metadata.canBorrow && canAddToLibraryForFree && (
                <div className={styles.addForFreeLegalText}>
                    <div>
                    {metadata.sellerOfRecord && !isJPAmazon && (<>
                        { translations.formatText("sold-by-seller-of-record", { sellerOfRecord: metadata.sellerOfRecord }) }
                        <br />
                    </>)}
                    {interpolateTranslation(
                        translations.getText("add-to-your-library-for-free-legal-warning"),
                        "kindleStoreTermsOfUse",
                        <span className={styles.textButton} tabIndex={0} role="button" onClick={openKindleStoreTermsOfUse}>
                            {translations.getText("kindle-store-terms-of-use")}
                        </span>
                    )}</div>
                    {metadata.sellerOfRecord && isJPAmazon && (
                        <div className={styles.textButton} tabIndex={0} role="button" onClick={openSellerOfRecordLegalText}>
                            {translations.formatText("sold-by-seller-of-record", { sellerOfRecord: metadata.sellerOfRecord })}
                        </div>
                    )}
                </div>
            )}
            <hr className={styles.sectionDivider} aria-hidden />
        </>)
    };

    return (
        <div className={`${styles.quickViewSlideHeader} ${styles[context.theme]}`}>
            <div className={styles.bookDetailHeader}>
                <div
                    className={styles.coverImageContainer}
                    onClick={handleCoverTap}
                >
                    <BookCoverWithPlaceholder
                        asin={metadata.asin}
                        authors={metadata.authors}
                        title={metadata.title ?? ""}
                        physicalId={metadata.physicalId}
                        imagePxSize={200}
                        imagePxType="width"
                    />
                </div>
                <div className={styles.metadataContainer}>
                    { isSponsored && <SponsoredLabel/>}
                    <div
                        className={[styles.title, truncateTitle ? styles.truncate : ""].join(" ")}
                        onClick={toggleTruncateTitle}
                        role="heading" aria-level={1}
                    >{metadata.title}</div>
                    <div className={styles.authorsContainer}>
                        <div className={[styles.authors, truncateAuthors ? styles.truncate : ""].join(" ")} onClick={toggleTruncateAuthors}>{metadata.authors}</div>
                    </div>
                    { isReady && (<>
                        { metadata.reviewsSummary !== null && ( // Hide for unreleased ASINs (undefined while loading, null if missing)
                            <StarRatingBar
                                reviewsSummary={metadata.reviewsSummary}
                                onTapCallback={onClickCustomerReviews}
                            />
                        )}

                        { getStickerSection() }

                        <div className={styles.buttonContainer}>
                            <AddToListButton asin={metadata.asin} />
                            <ShareButton item={metadata} />
                        </div>
                    </>)}
                </div>
            </div>
            {getReleaseDateSection()}
            {context.enablePostPurchaseStrip && (<PostPurchaseMessage metadata={metadata} />)}
            { isReady && getHeaderBottomSection()}
        </div>
    );
};

export default memo(QuickViewSlideHeader);
