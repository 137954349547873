import React, { useState, useContext, useRef, memo, useEffect } from "react";
import styles from "./ExpandableContent.module.scss";
import DeviceContext from "src/contexts/DeviceContext";
import TranslationsContext from "src/contexts/TranslationsContext";
import { Metrics } from "src/utils/metricsUtils";
import RichContent from "../RichContent/RichContent";

export enum ScrollOnCollapse {
    SELF,
    PARENT,
    NONE,
}

interface Props {
    content?: React.ReactNode;
    truncatedHeight?: string;
    truncatedMinHeight?: string;
    truncatedMaxHeight?: string;
    scrollOnCollapse?: ScrollOnCollapse;
    getScrollData: () => { scrollAreaScrollTop: number; miniHeaderOffset: number; } | undefined;
    metrics: Metrics;
}

const ExpandableContent: React.FC<Props> = ({
    content,
    truncatedHeight,
    truncatedMinHeight,
    truncatedMaxHeight,
    scrollOnCollapse,
    getScrollData,
    metrics,
}) => {
    const context = useContext(DeviceContext);
    const translations = useContext(TranslationsContext);
    const parentDiv = useRef<HTMLDivElement>(null);
    const [truncate, setTruncate] = useState(true);
    const [wouldTruncate, setWouldTruncate] = useState(false);
    const collapsedHeight = useRef(0);

    useEffect(() => {
        const parentContainer = parentDiv.current;
        if (!parentContainer) {
            return;
        }
        collapsedHeight.current = parentContainer.clientHeight;
        const hasOverflowed = parentContainer.scrollHeight > collapsedHeight.current;
        setTruncate(hasOverflowed);
        setWouldTruncate(hasOverflowed);
    }, []);

    const getClassNames = () =>
        [
            styles.content,
            styles[context.theme],
            truncate ? styles.truncate : "",
            wouldTruncate ? styles.wouldTruncate : ""
        ].join(" ");

    const truncatedCss = {
        maxHeight: truncatedMaxHeight || truncatedHeight || "fit-content",
        minHeight: truncatedMinHeight || truncatedHeight || "fit-content",
    };

    const toggleTruncated = () => {
        if (wouldTruncate) {
            metrics.recordBehavioralMetric(truncate ? "ReadMore.Click" : "ReadLess.Click", 1);
            const shouldTruncate = !truncate;
            setTruncate(shouldTruncate);
            if (shouldTruncate && scrollOnCollapse !== undefined && scrollOnCollapse !== ScrollOnCollapse.NONE) {
                const elem = scrollOnCollapse === ScrollOnCollapse.PARENT
                    ? parentDiv.current?.parentElement
                    : parentDiv.current; // ScrollOnCollapse.SELF
                const scrollData = getScrollData();
                if (elem) {
                    if (scrollData) {
                        const visibleScrollTop = scrollData.scrollAreaScrollTop + scrollData.miniHeaderOffset;
                        if (visibleScrollTop <= elem.offsetTop + collapsedHeight.current) {
                            return; // Already visible (and would remain so after collapse), so don't scroll
                        }
                    }
                    elem.scrollIntoView({ block: "start" });
                }
            }
        }
    };

    return (
        <>
            <div
                ref={parentDiv}
                className={getClassNames()}
                style={truncate ? truncatedCss : { maxHeight:  parentDiv.current?.scrollHeight }}
                onClick={toggleTruncated}
            >
                {content}
            </div>
            { wouldTruncate && (
                <div className={`${styles.readMoreButton} ${styles[context.theme]}`} onClick={toggleTruncated} role="button" tabIndex={0}>
                    {translations.getText(truncate ? "read-more" : "read-less")}
                    <div className={truncate ? styles.downArrow : styles.upArrow} />
                </div>
            )}
        </>
    );
};

interface ExpandsableRichContentProps {
    richContent: RichContent;
    truncatedHeight?: string;
    truncatedMinHeight?: string;
    truncatedMaxHeight?: string;
    scrollOnCollapse?: ScrollOnCollapse;
    getScrollData: () => { scrollAreaScrollTop: number; miniHeaderOffset: number; } | undefined;
    metrics: Metrics;
}

const ExpandableRichContent: React.FC<ExpandsableRichContentProps> = ({
    richContent,
    truncatedHeight,
    truncatedMinHeight,
    truncatedMaxHeight,
    scrollOnCollapse,
    getScrollData,
    metrics,
}) => {
    return (
        <ExpandableContent
            content={<RichContent content={richContent} />}
            truncatedHeight={truncatedHeight}
            truncatedMinHeight={truncatedMinHeight}
            truncatedMaxHeight={truncatedMaxHeight}
            getScrollData={getScrollData}
            scrollOnCollapse={scrollOnCollapse}
            metrics={metrics}
        />
    );
};

export default memo(ExpandableContent);
export {ExpandableRichContent };
