import React, { useContext, useRef } from "react";
import styles from "./ReKindleError.module.scss";
import DeviceContext from "src/contexts/DeviceContext";
import TranslationsContext from "src/contexts/TranslationsContext";

type PropTypes = {
    dismiss: () => void;
};

const ReKindleError: React.FC<PropTypes> = ({ dismiss }: PropTypes) => {
    const context = useContext(DeviceContext);
    const translations = useContext(TranslationsContext);
    const a11yInitialTarget = useRef<HTMLDivElement>(null);

    const itemContainerClassName = `${styles.itemContainer} ${styles[context.theme]}`;

    const maybeStopPropagation = (event: React.UIEvent) => {
        const ct = event.currentTarget;
        if (ct.scrollTop || ct.scrollHeight !== ct.clientHeight) {
            event.stopPropagation();
        }
    };

    return (
        <div className={itemContainerClassName}>
            <div className={styles.chevronIconContainer}>
                <div className={styles.chevronIcon} onClick={dismiss}/>
            </div>
            <header className={styles.header} ref={a11yInitialTarget} tabIndex={-1}>
                <h1>{translations.getText("re_kindle_app_version_error_view_title")}</h1>
            </header>
            <main
                className={styles.main}
                onScroll={maybeStopPropagation}
                onTouchStart={maybeStopPropagation}
                onTouchMove={maybeStopPropagation}
                onTouchEnd={maybeStopPropagation}
                onTouchCancel={maybeStopPropagation}
            >
                <p>{translations.getText("re_kindle_app_version_error_view_description")}</p>
            </main>
            <footer className={styles.footerButtons}>
                <button className={styles.cancelButton} onClick={dismiss}>
                    {translations.getText("cancel")}
                </button>
            </footer>
        </div>
    );
};

export default ReKindleError;
