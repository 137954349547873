import { asLegacyOverview, asLegacySeries, canBuyForFree, getByLine, hasBorrowCta, hasFreeAfrOption, hasKuUpsell, hasLimberCta, hasPreOrderCta, hasPurchaseCta, hasReadNowCta, hasSampleCta, isRpl, primaryPurchaseCta, themedImages } from "./aapiResponseUtils";
import { AsinOffers, fetchAsinOffers, perfToString, fetchAsinMetadataAapiWithPerf, fetchAudibleConditionsOfUseAjax, fetchAsinMetadataMesWithPerf } from "./ajaxUtils";
import debug from "./debugUtils";
import { NativeAPI } from "./deviceUtils";

export type BookOfferStatus = {
    hasSample: boolean;
    canBorrow: boolean;
};

const activeFetches = new Map<string, Promise<any>>();
const asinMetadataMap = new Map<string, QuickViewAsinMetadata>();
const offersMap = new Map<string, Promise<PersonalizedAction[]>>();
const asinLoadFailures = new Map<string, number>();
const localeToCou = new Map<string, string>();

export const delayPromise = (ms: number) => new Promise((r) => setTimeout(r, ms));
const withRetries = <Type>(f: () => Promise<Type>, delay = 500, retries = 5): Promise<Type> =>
    new Promise<Type>((resolve, reject) =>
        f()
            .then(resolve)
            .catch((reason) => {
                if (retries > 0) {
                    return delayPromise(delay)
                        .then(() => withRetries(f, delay + 100, retries - 1))
                        .then(resolve)
                        .catch(reject);
                }
                return reject(reason);
            })
    );

const filterMes = (unfilteredMes?: [MESRecItem]) => {
    if (unfilteredMes) {
        const output = unfilteredMes
            ?.map(item => ({
                tag: item.tag,
                attr: item.attr,
                asins: item.asins?.filter(asin => asin?.startsWith("B")),
            }))
            ?.filter(item => item?.asins?.length ?? 0 > 0);
        return output;
    }
    return unfilteredMes;
}

const convertAapiItem = (item: AapiAsinMetadata): QuickViewAsinMetadata => {
    debug.log(item);

    const newItem: QuickViewAsinMetadata = {
        asin: item.asin,
        physicalId: item.productImages?.images?.[0]?.hiRes?.physicalId || item.productImages?.images?.[0]?.lowRes?.physicalId,
        title: item.title,
        authors: getByLine(item.byLine?.contributors),
        description: item.description?.content,
        overview: asLegacyOverview(item),
        reviewsSummary: {
            numberOfStars: item.customerReviewsSummary?.rating?.fullStarCount,
            numberOfReviews: item.customerReviewsSummary?.count?.displayString,
            hasHalfStar: item.customerReviewsSummary?.rating?.hasHalfStar,
        },
        series: asLegacySeries(item),
        audibleUrl: item.audible?.audioSample?.url,
        audibleDisplayText: item.audible?.audioSample?.displayText,
        isTandem: !!item.audible?.tandemNotification,
        kindleProgram: { stickerStyleCode: item.kindleProgramLowCost?.stickerStyleCode, physicalId: item.kindleProgramLowCost?.image?.physicalId },
        badgeImages: themedImages(item),
        sellerOfRecord: item.buyingOptions?.[0]?.merchant?.entity?.merchantName,
        hasSample: hasSampleCta(item.buyingOptions),
        canBorrow: hasBorrowCta(item.buyingOptions),
        canBuy: hasPurchaseCta(item.buyingOptions),
        canReadNow: hasReadNowCta(item.buyingOptions),
        canBuyForFree: canBuyForFree(item.buyingOptions),
        canPreOrder: hasPreOrderCta(item.buyingOptions),
        primaryPurchaseBuyingOption: primaryPurchaseCta(item.buyingOptions),
        allBuyingOptions: item.buyingOptions,
        hasKuUpsell: hasKuUpsell(item.buyingOptions),
        isRPL: isRpl(item.kindleProgramLowCost),
        isAFR: hasLimberCta(item.buyingOptions),
        hasFreeAfrOption: hasFreeAfrOption(item.buyingOptions),
        isMagazine: item.productCategory?.glProductGroup?.symbol === "gl_digital_periodicals",
        isEbook: item.productCategory?.glProductGroup?.symbol === "gl_digital_ebook_purchase",
        releaseDate: {
            displayString: item.releaseDate?.displayString,
            date: new Date(item.releaseDate?.date || NaN),
            isPrerelease: new Date(item.releaseDate?.date || NaN) >= new Date(),
        },
        postPurchaseMessageShort: item.postPurchaseString?.shortMessage?.displayString?.text,
        postPurchaseMessageLong: item.postPurchaseString?.longMessage?.displayString?.text,
        whisperSyncForVoice: item.whisperSyncForVoice,
        mes: filterMes(item.mes),
        loaded: true,
        loadFailed: false,
    };

    debug.log(newItem);

    return newItem;
};

const requestAsinMetadata = async (asin: string, mode: string): Promise<QuickViewAsinMetadata> => {
    const isQv = mode === "qv";
    const isMes = mode === "mes";

    debug.log(`requestAsinMetadata: ${asin} ${mode}`);
    if ((isQv && debug.get("disableQvMetadataLoads")) || (isMes && debug.get("disableMoreLikeThisLoads"))) {
        await delayPromise(2000);
        const status = await NativeAPI.getConnectionStatus();
        return {
            asin,
            loadFailed: true,
            error: `debug::disableMetadataLoads - ${status?.connectionState}`
        };
    }
    if (debug.get("delayMetadataLoads")) {
        await delayPromise(2000);
    }

    const responseWithPerf = isMes
        ? await fetchAsinMetadataMesWithPerf([asin])
        : await fetchAsinMetadataAapiWithPerf([asin]);
    debug.log(`ASIN: ${asin}\n${perfToString(responseWithPerf)}`);
    if (responseWithPerf.isError) {
        return {
            asin,
            loadFailed: true,
            error: responseWithPerf.error
        };
    }
    const response = responseWithPerf.result;
    if (!response || (isQv && (response as Array<AapiAsinMetadata>).length === 0)) {
        return {
            asin,
            loadFailed: true,
            error: 'Invalid response'
        };
    }
    const item = (response as Array<AapiAsinMetadata>)[0];

    debug.log(item);
    try {
        return convertAapiItem(item as AapiAsinMetadata);
    } catch (exception) {
        return {
            asin,
            loadFailed: true,
            error: `Exception: ${exception}`
        };
    }
};

const batchRequestMesAsinMetadata = async (asins: string[]): Promise<QuickViewAsinMetadata[]> => {
    debug.log("batchRequestMesAsinMetadata");
    const responseWithPerf = await fetchAsinMetadataMesWithPerf(asins).catch((it) => {
        debug.log("batchRequestMesAsinMetadata.failed");
        debug.log(it);
        return it;
    });
    if (!responseWithPerf) {
        // TODO: Is there a world where this happens instead of the other promise being rejected?
        throw Error("batchRequestMesAsinMetadata: Invalid ASIN metadata response");
    }
    const response = responseWithPerf.result;
    debug.log(`ASIN: ${asins.join(",")}\n${perfToString(responseWithPerf)}`);

    return response.map(convertAapiItem);
};

const convertAsinOffers = (asinOffers: AsinOffers): PersonalizedAction[] => {
    debug.log(asinOffers);
    const offers = asinOffers.resources
        ?.pop()
        ?.personalizedActionOutput?.personalizedActions?.filter((action) => !action?.offer?.conditional);
    if (!offers) {
        debug.error(`Invalid ASIN offers response ${asinOffers.httpStatusCode} ${asinOffers.status}`);
    }
    debug.log(offers);
    return offers || [];
};

const requestAsinOffers = async (asin: string): Promise<PersonalizedAction[]> => {
    if (debug.get("disableBiFrostLoads")) {
        return new Promise(() => { /* */ });
    }
    const fetchKey = `${asin}-offers`;
    debug.log(`requestAsinOffers: ${fetchKey}`);
    const maybeActiveFetch = activeFetches.get(fetchKey);
    if (maybeActiveFetch) {
        debug.log("Found active fetch for key: " + fetchKey);
        return maybeActiveFetch;
    }
    const fetchPromise = withRetries(() => fetchAsinOffers(asin)).then((offers) => convertAsinOffers(offers));
    activeFetches.set(fetchKey, fetchPromise);
    return fetchPromise.finally(() => activeFetches.delete(fetchKey));
};

const getBackoffDelayForMetadataRetry = (failures: number) => {
    const maxDelay = 15000; // 15 seconds
    const defaultDelay = failures * Math.ceil(failures / 10) * 100;
    if (defaultDelay > maxDelay) {
        const jiggle = (failures % 10) * (Date.now() % 100) + (Date.now() % 100);
        return maxDelay + jiggle;
    }
    return defaultDelay;
}

// Don't actually make a request if we know we're offline
const requestAsinMetadataIfOnline = async (asin: string, mode: string): Promise<QuickViewAsinMetadata> => {
    return navigator.onLine
        ? requestAsinMetadata(asin, mode)
        : { asin, loadFailed: true, error: `navigator.onLine: ${navigator.onLine}` };
};

const fetchMetadata = async (asin: string, mode: string): Promise<QuickViewAsinMetadata> => {
    const failures = asinLoadFailures.get(getAsinKey(asin, mode));
    if (failures) {
        // Add some retry backoff
        const delay = getBackoffDelayForMetadataRetry(failures);
        debug.log(`fetchMetadata: backing off for ${delay}ms before fetching ${asin}, failure count: ${failures}`);
        return delayPromise(delay).then(() => requestAsinMetadataIfOnline(asin, mode));
    }
    return requestAsinMetadataIfOnline(asin, mode);
};

export const batchGetMesMetadata = async (asins: string[]) => {
    const mode = "mes";
    const fetchKey = "mes_" + asins.join(",");
    debug.log(`batchGetMesMetadata: ${fetchKey}`);

    if (debug.get("disableQvMetadataLoads")) {
        return new Promise(() => { /* */ });
    }

    const maybeActiveFetch = activeFetches.get(fetchKey);
    if (maybeActiveFetch) {
        debug.log("Found active fetch for key: " + fetchKey);
        return maybeActiveFetch;
    }

    const unfetchedAsins = asins.filter(it => (
        !(
            activeFetches.has(getAsinKey(it, mode))
            || activeFetches.has(getAsinKey(it, "qv"))
        )
        && !(
                asinMetadataMap.has(getAsinKey(it, mode))
                || asinMetadataMap.has(getAsinKey(it, "qv"))
            )
        && it.length === 10)
    );

    if (unfetchedAsins.length === 0) {
        return Promise.resolve([]);
    }

    // TODO: safely gate new requests behind the batch fetch and clean up afterwards
    // Break unfetched ASINs array down into 30 ASIN batches.
    // This should be handled on the back end instead, since the 30 ASIN limit is in AAPI
    const promises = [];
    const batchSize = 30;
    for (let offset = 0; offset < unfetchedAsins.length; offset += batchSize) {
        const subBatch = unfetchedAsins.slice(offset, offset + batchSize);
        subBatch.forEach(asin => {
            const asinFetchKey = getAsinKey(asin, mode);
            // activeFetches.set(asinFetchKey, )
        });
        promises.push(
            batchRequestMesAsinMetadata(subBatch)
                .then((mds) => {
                    mds.forEach((md) => {
                        asinMetadataMap.set(getAsinKey(md.asin, mode), md)

                    });
                    return mds;
                })
        );
    }
    const batchPromise = Promise.all(promises);

    activeFetches.set(fetchKey, batchPromise);

    return batchPromise.finally(() => activeFetches.delete(fetchKey));
};

export const getOffers = async (asin: string): Promise<PersonalizedAction[] | undefined> => {
    const offers = offersMap.get(asin);
    if (offers) {
        return offers;
    }
    if (debug.get("disableBiFrostLoads")) {
        return new Promise(() => { /* */ });
    }
    const offersPromise = requestAsinOffers(asin);
    offersMap.set(asin, offersPromise);
    offersPromise.catch(() => { offersMap.delete(asin); });
    return offersPromise;
};

const getAsinKey = (asin: string, mode: string) => `${asin}_${mode}`;

const getMetadataBase = async (asin: string, mode: string): Promise<QuickViewAsinMetadata> => {
    if (asin.length !== 10) {
        return {
            asin,
            loadFailed: true,
            unrecoverableError: true,
            error: "Invalid ASIN",
        };
    }
    const asinKey = getAsinKey(asin, mode);
    const maybeActiveFetch = activeFetches.get(asinKey);
    if (maybeActiveFetch) {
        debug.log("Found active fetch for key: " + asinKey);
        return maybeActiveFetch;
    }

    const metadata = asinMetadataMap.get(asinKey);
    if (metadata) {
        return metadata;
    }

    const metadataPromise = fetchMetadata(asin, mode).then((metadata) => {
        if (metadata.loadFailed) {
            asinLoadFailures.set(asinKey, (asinLoadFailures.get(asinKey) || 0) + 1);
        } else {
            asinMetadataMap.set(asinKey, metadata);
        }
        return metadata;
    });

    activeFetches.set(asinKey, metadataPromise);
    return metadataPromise.finally(() => activeFetches.delete(asinKey));
};

export const getQvMetadata = async (asin: string): Promise<QuickViewAsinMetadata> => {
    return getMetadataBase(asin, "qv") as Promise<QuickViewAsinMetadata>;
}

export const getMesMetadata = async (asin: string): Promise<QuickViewAsinMetadata> => {
    // MES is a subset of full QV data, so check for that before making an MES mode get
    const qvAsinKey = getAsinKey(asin, "qv");
    const maybeActiveFetch = activeFetches.get(qvAsinKey);
    if (maybeActiveFetch) {
        debug.log(`Found active fetch for key: ${qvAsinKey}`);
        return maybeActiveFetch;
    }
    const metadata = asinMetadataMap.get(qvAsinKey);
    if (metadata) {
        return metadata;
    }
    return getMetadataBase(asin, "mes") as Promise<QuickViewAsinMetadata>;
}

export const isSponsoredAsin = (input: QuickViewAsinMetadata) => !!(input?.additionalData?.adClickLogUrl);

export const fetchAudibleConditionsOfUse = async (locale: string): Promise<string> => {
    const fetchKey = `audibleCOU-${locale}`;
    const maybeActiveFetch = activeFetches.get(fetchKey);
    if (maybeActiveFetch) {
        debug.log("Found active fetch for key: " + fetchKey);
        return maybeActiveFetch;
    }
    const data = localeToCou.get(fetchKey);
    if (data) {
        return data;
    }
    const fetchPromise = fetchAudibleConditionsOfUseAjax(locale);
    activeFetches.set(fetchKey, fetchPromise);
    return fetchPromise
        .then(response => { localeToCou.set(fetchKey, response); return response; })
        .finally(() => activeFetches.delete(fetchKey));
}
