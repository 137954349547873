import React from "react";
import { CONTENT_TYPE } from "src/utils/asinUtils";

export type FailableResult = { success: boolean; reasonCode?: string; borrowLimit?: boolean, redirectUrl?: string; };
type FailableResultConsumer = (result: FailableResult) => void;
export const onSuccess = (f: FailableResultConsumer) => (result: FailableResult) => {
    if (result.success) {
        f(result);
    }
    return result;
};
export const onFailure = (f: FailableResultConsumer) => (result: FailableResult) => {
    if (!result.success) {
        f(result);
    }
    return result;
};

export interface AcquisitionManagerContextI {
    isBorrowed: (asin: string) => boolean;
    wasReturned: (asin: string) => boolean;
    isOwned: (asin: string) => boolean;
    isSampleOwned: (asin: string) => boolean;
    returnAsin: (asin: string) => void;
    redeemOfferByActionType: (asin: string, actionType: string, focusCallback?: VoidFunction) => Promise<FailableResult>;
    openBook: (asin: string, title?: string, authors?: string, type?: CONTENT_TYPE, coverImagePosition?: QuickViewCoverImagePosition | undefined) => Promise<void>;
    isOpening: (asin?: string) => boolean;
    ownershipFetched: boolean;
    lastUpdateTimestamp: number;
}

const noOp = () => { /* NoOp */ };
const noOpBool = () => false;
const noOpAsync = async () => { return; };
const AcquisitionManagerContext = React.createContext<AcquisitionManagerContextI>({
    isBorrowed: noOpBool,
    wasReturned: noOpBool,
    isOwned: noOpBool,
    isSampleOwned: noOpBool,
    returnAsin: noOp,
    redeemOfferByActionType: async () => ({ success: false, reasonCode: "default", borrowLimit: false }),
    openBook: noOpAsync,
    isOpening: noOpBool,
    ownershipFetched: false,
    lastUpdateTimestamp: 0,
});

export default AcquisitionManagerContext;
